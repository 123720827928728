import React, { ReactElement } from "react";
import cn from "classnames";

import { ButtonLink } from "../../Button";
import { CustomizedDialogs } from "../../Footer/Modal";
import { ReactComponent as Logo } from "../../../assets/logo-dark.svg";
import { ReactComponent as ErrorImage } from "../../../assets/error.svg";
import { ReactComponent as CheckEmailBackground } from "../../../assets/check-email-background.svg";

import styles from "./styles.mod.scss";

const ErrorComponent = (): ReactElement => {
  const containerCn = cn("container", styles.container);

  return (
    <section className={styles.section}>
      <div className={styles.background}>
        <CheckEmailBackground height="100%" width="100%" />
      </div>
      <div className={containerCn}>
        <div className="row">
          <div className="col-12">
            <div className={styles.logoContainer}>
              <a className={styles.logoLink} href="/">
                <Logo />
              </a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6">
            <h1 className={styles.title}>
              Huch, hier ist etwas schiefgelaufen...
            </h1>
            <div className={styles.descriptionContainer}>
              <p>
                Die Leitung ist unterbrochen, die Seite ist nicht vorhanden.
                Vielleicht war es nur ein Tippfehler? Vielleicht hilft einmal
                neu laden? Sonst geh zurück zur Startseite und versuche es
                später noch mal.
              </p>
            </div>
            <div className={styles.buttonContainer}>
              <ButtonLink to="/">Zur Startseite</ButtonLink>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className={styles.imageContainer}>
              <ErrorImage />
            </div>
          </div>
        </div>
        <div className="row flex-grow-1 align-items-end">
          <div className="col-12">
            <div className={styles.agrrementsContainer}>
              <CustomizedDialogs
                agreementLinkClassName={styles.agreementLink}
                withoutCopyright
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ErrorComponent;
